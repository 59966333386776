import React from "react"
import {
  addSumForVehicleCategoryAndConvertPrice,
  convertPrice,
  getDiscountValue,
  getDiscountValueWithCategory,
  isAdditionalHidden,
  secondsToMinutes,
} from "../../utils/helper.service"

import { useTranslation } from "gatsby-plugin-react-i18next";

const VehicleBlock = props => {
  const { t } = useTranslation()
  return (
    <div className="notification p-2">
      <div className="field">
        <div className={"columns"}>
          <div className={"column is-narrow"}>
            <img
              className={"box"}
              src={`https://s3.eu-north-1.amazonaws.com/assets.washcar/img/svg/${props.selectedCategory.image}`}
            />
          </div>

          <div className={"column"}>
            <h2 className="subtitle">
              {props.selectedVehicle.make_and_model} <br />{" "}
              {props.selectedVehicle.reg_number}
            </h2>
            <button
              type="button" 
      className="button is-danger"
      onClick={() => props.handleDeleteVehicle(props.vehicleId)}
    >
      {t('remove')}
    </button>
          </div>



        </div>
  
        {props.services.map(y =>
          y.vehicle_id === props.vehicleId
            ? Object.keys(y.vehicle_services)
         
            .filter(key => y.vehicle_services[key].vehicle_categories.includes(parseInt(props.selectedVehicle.category_id)))
            .map(key => (            
                <div
                  key={props.vehicleId + "-" + y.vehicle_services[key].id}
                  className="control box"
                >
                 
                  <label className="radio">
                    <input
                      onChange={e =>
                        props.handleChangeServices(
                          props.vehicleId,
                          y.vehicle_services[key].id,
                          e
                        )
                      }
                      defaultChecked={
                        parseInt(y.selected_service_id) ===
                        parseInt(y.vehicle_services[key].id)
                      }
                      value={y.vehicle_services[key].id}
                      id={
                        "service_" +
                        props.vehicleId +
                        " " +
                        y.vehicle_services[key].id
                      }
                      name={"vehicle[" + props.vehicleId + "]service_id"}
                      type="radio"
                    />

                    <span className={"ml-1 is-size-4"}>
                      {y.vehicle_services[key].label}
                    </span>

                    <div className={"mt-2 mb-2"}>
                      <div className={"tag is-rounded is-size-5 mr-2"}>
                   
                        {props.customer.discount_in_percents === null  ? (
                          addSumForVehicleCategoryAndConvertPrice(
                            y.vehicle_services[key],
                            props.selectedCategory
                          )
                        ) : (
                          <div>
                            <span className={"ml-1 is-size-6"}>
                              <b>
                                {getDiscountValueWithCategory(
                                  y.vehicle_services[key],
                                  props.customer.discount_in_percents,
                                  props.selectedCategory
                                )}
                              </b>
                            </span>


                            {y.vehicle_services[key].accept_discounts  && (
                              <span
                                className={"is-size-7 ml-1"}
                                style={{ textDecoration: `line-through` }}
                              >
                                {addSumForVehicleCategoryAndConvertPrice(
                                  y.vehicle_services[key],
                                  props.selectedCategory
                                )}
                              </span>
                            )}
                            
                          </div>
                        )}
                      </div>

                      <div className={"tag is-rounded is-size-5"}>
                        {secondsToMinutes(y.vehicle_services[key].duration)} min
                      </div>
                    </div>
                  </label>

                  <div>
                    <div
                      className={
                        "as-for-vehicle-" +
                        props.selectedVehicle.id +
                        " additional-services  additional-services-" +
                        props.selectedVehicle.id +
                        "-" +
                        y.vehicle_services[key].id +
                        " " +
                        isAdditionalHidden(key)
                      }
                    >
                      {y.vehicle_services[key].additional_services &&
                        Object.keys(
                          y.vehicle_services[key].additional_services
                        ).map(key2 => (
                          <div
                            key={
                              y.vehicle_services[key].additional_services[key2]
                                .id
                            }
                            className="control notification is-info"
                          >
                            <label className="checkbox">
                              <input
                                onChange={e =>
                                  props.handleCheckAdditionalServicesNew(
                                    props.vehicleId,
                                    y.vehicle_services[key].id,
                                    e
                                  )
                                }
                                value={
                                  y.vehicle_services[key].additional_services[
                                    key2
                                  ].id
                                }
                                type="checkbox"
                              />
                              <span className="is-size-6 ml-1">
                                {
                                  y.vehicle_services[key].additional_services[
                                    key2
                                  ].label
                                }
                              </span>
                              <div className="mt-2 mb-2">
                                {props.customer.discount_in_percents ===
                                null ? (
                                  <div className="tag is-rounded mb-1 mr-2">
                                    <span className="ml-1 is-size-6">
                                      <b>
                                        {getDiscountValue(
                                          y.vehicle_services[key]
                                            .additional_services[key2].prices[
                                            props.selectedVehicle.category_id
                                          ],
                                          props.customer.discount_in_percents
                                        )}
                                      </b>
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <b>
                                      {getDiscountValue(
                                        y.vehicle_services[key]
                                          .additional_services[key2].prices[
                                          props.selectedVehicle.category_id
                                        ],
                                        props.customer.discount_in_percents
                                      )}
                                    </b>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                      }}
                                      className="is-size-7 ml-1"
                                    >
                                      {convertPrice(
                                        y.vehicle_services[key]
                                          .additional_services[key2].prices[
                                          props.selectedVehicle.category_id
                                        ]
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div className="tag is-rounded mb-1 mt-1">
                                  <span className="ml-1 is-size-6 has-text-info">
                                    {secondsToMinutes(
                                      y.vehicle_services[key]
                                        .additional_services[key2].duration
                                    )}{" "}
                                    min
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))
            : null
        )}
      </div>
    </div>
  )
}

export default VehicleBlock

